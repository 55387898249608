function isLocalHost(hostname = window.location.hostname) {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname)
}

function createLinkConfig () {
  let linkConfig = {
    web: "/",
    h5: "/",
    online: "https://shunlong.s5.udesk.cn/im_client/?web_plugin_id=1812&group_id=1048",
    login: "https://fo.wsgoldfo003.com/Frontoffice/",
    disclaimer: "/disclaimer",
    risk: "/risk_disclosure",
    agreement: "/client_agreement",
    policy: "/privacy_policy",
    android: [
      "https://downloads.atzrceuc.com/source/material/WSJY_GW.apk",
    ],
    android_backup: "https://downloads.atzrceuc.com/source/material/WSJY_GW.apk",
  };

  /**
   * 本地开发或者UAT 设定:
   * isLocalHost() || window.location.hostname.includes("atbgovernor")
   * 
   * 其余视为正式环境，动态域名
   */
  if (isLocalHost() || window.location.hostname.includes("atbgovernor")) {
    linkConfig.web = "https://wasin.atbgovernor.com";
    linkConfig.h5 = "http://h5-wasin-official.atbgovernor.com";
  } else {
    linkConfig.web = window.location.origin.replace("acat", "www");
    linkConfig.h5 = window.location.origin.replace("acat", "h5");
  }

  linkConfig.disclaimer = linkConfig.web + linkConfig.disclaimer;
  linkConfig.risk = linkConfig.web + linkConfig.risk;
  linkConfig.agreement = linkConfig.web + linkConfig.agreement;
  linkConfig.policy = linkConfig.web + linkConfig.policy;

  return linkConfig;
}

const sourceUrl = "_s_u_l_";
const linkConfig = createLinkConfig();
export { linkConfig, sourceUrl };
